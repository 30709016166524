<template>
  <div id="modals" class="absolute z-100" />
  <toasts />
  
  <kennel-profile-layout-menu />
  
  <div class="bg-white">
    <slot />
  </div>
  
  <layout-footer />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Toasts from "@/layout/Toasts.vue";
import LayoutFooter from "@/layout/LayoutFooter.vue";
import KennelProfileLayoutMenu from "@/layout/KennelProfileLayoutMenu.vue";
import userStore from "@/store/user-store";
import { AuthedUser } from "@/@types";

export default defineComponent({
  components: { KennelProfileLayoutMenu, LayoutFooter, Toasts },
  props: {
    sharedUser: {
      type: Object as PropType<AuthedUser>,
      required: false,
    },
  },
  created() {
    userStore.setup(this.sharedUser ?? null, null, false, 0);
  },
});
</script>

<style lang="scss">
body, pre {
  background-color: theme('colors.gray.100');
}
</style>
