import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import userStore from "@/store/user-store";

let bugsnagVue: any;

if (window.route().params.hasOwnProperty('previewPdf')) {
  bugsnagVue = null;
} else {
  Bugsnag.start({
    apiKey: 'b7da277194039ad47a8c775e20416453',
    plugins: [new BugsnagPluginVue()],
    appVersion: window.app_version,
    onError: function(event) {
      const user = userStore.getState().user;

      if (user) {
        event.setUser(user.id.toString(), user.email, user.display_name)
      } else {
        event.setUser(window.cookie_uuid)
      }
    },
    releaseStage: window.location.host.includes('.test') ? 'local' : undefined,
  })

  bugsnagVue = Bugsnag.getPlugin('vue');
}

export default bugsnagVue;
