import { createApp, h } from "vue";
import { createInertiaApp, Link } from '@inertiajs/vue3';
import clickOutside from './library/click-outside';
import { User } from "@/@types";
import userStore from "@/store/user-store"
import FaIcon from "@/components/FaIcon.vue";
import '@/library/font-awesome';
import layoutStore from "@/store/layout-store";
import bugsnagVue from "@/library/bugsnag";

Object.typedKeys = Object.keys as any
let app: ReturnType<typeof createInertiaApp>;

const setup = (
  layoutCallback: (name: string) => any,
  resolvePageComponent: (name: string) => any,
): ReturnType<typeof createInertiaApp> => {
  app = createInertiaApp({
    progress: {
      color: '#7cc377',
    },
    resolve: async (name) => {
      const page = await resolvePageComponent(name);
      page.default.layout = page.default.layout || layoutCallback(name)
      return page
    },
    setup({ el, App, props, plugin }) {
      const vueApp = createApp({ render: () => h(App, props) })
        .use(plugin)
        .mixin({
          computed: {
            user(): User | null {
              return userStore.getState().user;
            },
            isLogged(): boolean {
              return userStore.isLogged();
            },
            hasKennel(): boolean {
              return this.user ? !!this.user.current_team_id : false;
            },
            isMobile(): boolean {
              return [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i].some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
              });
            },
            isLarge(): boolean {
              return layoutStore.getState().width > 1023;
            },
            isAndroid(): boolean {
              return this.mobileOperatingSystem === 'Android';
            },
            isiOS(): boolean {
              return this.mobileOperatingSystem === 'iOS';
            },
            window() {
              return window;
            },
            mobileOperatingSystem(): "Windows Phone" | "Android" | "iOS" | null {
              const userAgent = navigator.userAgent || navigator.vendor;

              if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
              } else if (/android/i.test(userAgent)) {
                return "Android";
              } else if (/iPad|iPhone|iPod/.test(userAgent)) {
                return "iOS";
              }

              return null;
            },
          },
        })
        .component('InertiaLink', Link)
        .component('FaIcon', FaIcon)
        .directive('click-outside', clickOutside);
      
      if (bugsnagVue) {
        vueApp.use(bugsnagVue);
      }
      
      vueApp.mount(el);
    },
  })
  
  return app;
}

// const _setup = (
// //   resolveComponent: (name: string) => any,
// //   store: { pageUrl: (to: Page, argument: number|string|null) => string },
// // ): ReturnType<typeof createApp> => {
// //   app = createApp({
// //     render: () => h(App, {
// //       initialPage: JSON.parse(el.dataset.page as string),
// //       resolveComponent,
// //     }),
// //   });
// //
// //   return app;
// // };

export { setup, app };
