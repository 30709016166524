<template>
  <button
    v-if="state !== 'COMPLETED' && ! hideFacade"
    type="button"
    class="fixed bottom-[20px] right-[27px] z-40 flex size-[60px] items-center justify-center rounded-full bg-green-500 text-white sm:right-[24px]"
    @click="onClick"
    @mouseenter="init"
  >
    <loader 
      v-if="openAfterComplete"
      class="white"
    />
    
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 800 800"
      height="32px"
      width="32px"
      role="img"
      alt="Chattikon"
      class="fill-current"
    ><path
      color="current"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M400 26.2c-193.3 0-350 156.7-350 350 0 136.2 77.9 254.3 191.5 312.1 15.4 8.1 31.4 15.1 48.1 20.8l-16.5 63.5c-2 7.8 5.4 14.7 13 12.1l229.8-77.6c14.6-5.3 28.8-11.6 42.4-18.7C672 630.6 750 512.5 750 376.2c0-193.3-156.7-350-350-350zm211.1 510.7c-10.8 26.5-41.9 77.2-121.5 77.2-79.9 0-110.9-51-121.6-77.4-2.8-6.8 5-13.4 13.8-11.8 76.2 13.7 147.7 13 215.3.3 8.9-1.8 16.8 4.8 14 11.7z"
    /></svg>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { loadScript } from "@/library/load-script";
import Loader from "@/components/Loader.vue";

type MessengerState = 'IDLE'|'INITIATED'|'COMPLETED'

export default defineComponent({
  components: { Loader },
  data() {
    return {
      state: 'IDLE' as MessengerState,
      openAfterComplete: false,
    }
  },
  computed: {
    hideFacade(): boolean {
      return (this.isMobile && [
        'login',
        'ads.show',
        'ads.create',
        'ads.edit',
        'sales.create',
        'sales.edit',
        'breeds.show',
        'kennels.map',
        'breedGuideResults.create',
        'breedGuideResults.show',
        'foodGuideResults.create',
        'foodGuideResults.show',
      ].includes(window.route().current()!))
      || [
        'conversations.index',
        'conversations.show',
        'profiles.show',
        'profiles.create',
        'insuranceInvites.accept',
        'contracts.edit',
      ].includes(window.route().current()!);
    },
    
    loadDirectly(): boolean {
      return ['contact'].includes(window.route().current()!)
    }
  },
  mounted() {
    if (! this.hideFacade) {
      setTimeout(this.init, this.loadDirectly ? 1 : 10 * 1000);
    }
  },
  methods: {
    onMouseEnter(): void {
      this.init();
    },

    onClick(): void {
      this.openAfterComplete = true;
      this.init();
    },

    init(): void {
      if (this.state !== 'IDLE') return;
      
      this.state = 'INITIATED';

      loadScript('https://embed.tawk.to/665ebb39981b6c5647784225/1hvh0mm22', () => {
        setTimeout(() => this.onFinished(), 4000);
      });
    },

    onFinished() {
      this.state = 'COMPLETED';
      this.openChatAfterComplete();
    },

    openChatAfterComplete(): void {
      if (this.openAfterComplete) {
        this.openChat();
      }
    },

    openChat(): void {
      window.$_Tawk.maximize();
    }
  },
});
</script>
