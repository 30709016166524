import Store from "./store"
import { AuthedUser, Kennel, Media } from "@/@types";

interface UserStoreInterface extends Object {
  user: AuthedUser|null;
  kennel: Kennel|null;
  unseenMessages: number;
  unseenMatches: number;
  hasProfile: boolean;
  hasPremium: boolean;
  avatar: Media|null;
}

class UserStore extends Store<UserStoreInterface> {
  setup(user: AuthedUser|null, kennel: Kennel|null, hasProfile = false, hasPremium = false, unseenMatches = 0): void {
    this.state.user = user;
    this.state.kennel = kennel;
    this.state.unseenMatches = unseenMatches;
    this.state.hasProfile = hasProfile;
    this.state.hasPremium = hasPremium;

    if (this.isLogged()) {
      this.state.unseenMessages = user!.unseen_messages;
      this.state.avatar = user!.avatar!;
    }
  }

  setUnseenMessages(unseenMessages: number): void {
    this.state.unseenMessages = unseenMessages;
  }

  setUnseenMatches(unseenMatches: number): void {
    this.state.unseenMatches = unseenMatches;
  }
  
  setAvatar(avatar: Media|null): void {
    this.state.avatar = avatar;
  }

  isLogged(): boolean {
    return !! this.state.user;
  }
}

const userStore: UserStore = new UserStore({
  user: null,
  kennel: null,
  unseenMessages: 0,
  unseenMatches: 0,
  hasProfile: false,
  hasPremium: false,
  avatar: null,
})

export default userStore
