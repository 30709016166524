<template>
  <div
    class="relative aspect-square shrink-0"
    :class="{
      'rounded-full border-2 border-purple-400': hasPremium
    }"
  >
    <fullscreen-image 
      v-if="avatar"
      alt=""
      :src="avatar.thumbnail"
      :fullscreen="avatar.fullscreen"
      img-class="bg-white h-full w-full rounded-full object-cover"
    />
    
    <div
      v-else
      class="flex size-full items-center justify-center rounded-full"
      :class="{
        'bg-purple-100 text-purple-600/50': hasPremium,
        'bg-gray-200 text-gray-500': ! hasPremium,
        'text-2xl lg:text-4xl': large,
        'text-xs': small,
      }"
    >
      <fa-icon icon="user" type="fas" />
    </div>
    
    <div 
      v-if="hasPremium"
      class="absolute bottom-0 left-1/2 z-10 flex -translate-x-1/2 items-center rounded-full bg-purple-500 px-1.5 font-bold text-white"
      :class="{
        'translate-y-1/3 py-[2px] text-2xs': large,
        'translate-y-1/2 py-1 text-2xs': ! large,
      }"
    >
      <fa-icon class="fa-sm" :icon="large ? 'crown' : 'star'" type="fas" />
      <span v-if="large" :class="large ? 'ml-1' : 'ml-0.5'">{{ large ? 'PREMIUM' : 'PRIO' }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Media } from "@/@types";
import FaIcon from "@/components/FaIcon.vue";
import FullscreenImage from "@/components/FullscreenImage.vue";

export default defineComponent({
  components: { FullscreenImage, FaIcon },
  props: {
    avatar: {
      type: Object as PropType<Media | null>,
      default: null,
    },
    hasPremium: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
