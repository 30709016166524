<template>
  <input
    id="image"
    type="file"
    name="images"
    accept="image/jpeg, image/png, image/webp"
    class="hidden"
    @change="onChange"
  >
  
  <div class="relative">
    <label
      for="image"
      class="relative flex cursor-pointer items-center rounded-md border border-gray-400 p-2 hover:bg-gray-50"
    >
      <div class="relative size-16">
        <user-avatar
          class="size-full"
          :avatar="avatar"
          :large="true"
        />
        
        <button
          v-if="avatar"
          class="absolute -bottom-1.5 -left-1.5 z-10 rounded-full bg-red-600 px-1 text-white hover:bg-red-700"
          @click.stop.prevent="removeImage"
        >
          <fa-icon icon="times" class="fa-fw fa-sm" />
        </button>
      </div>
          
      <div class="flex-1 pl-3 text-center text-sm font-bold uppercase text-gray-500">
        Välj bild
      </div>
      
      <div class="w-16" />
          
      <div
        v-if="mediaForm.isProcessing"
        class="absolute inset-x-0 bottom-0 z-10 flex h-2 overflow-hidden rounded-b-md bg-green-200"
      >
        <div
          style="transform-origin: left;"
          :style="{ transform: 'scaleX(' + (mediaForm.progress / 100) + ')' }"
          class="w-full bg-green-500 transition-transform duration-300"
        />
      </div>
    </label>
  </div>
      
  <form-error :errors="errors" field="image" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Media } from "@/@types";
import FormError from "@/components/FormError.vue";
import UserAvatar from "@/components/Profile/UserAvatar.vue";
import FaIcon from "@/components/FaIcon.vue";
import Errors from "@/library/errors";
import mediaMixin from "@/library/media-mixin";

export default defineComponent({
  components: { FaIcon, UserAvatar, FormError },
  mixins: [mediaMixin],
  props: {
    modelValue: {
      type: [Number, Blob] as PropType<Blob|Number|null>,
      default: null,
    },
    initialAvatar: {
      type: Object as PropType<Media|null>,
      default: null,
    },
    errors: {
      type: Object as PropType<Errors>,
      required: true,
    },
    mediaCollection: {
      type: String,
      default: 'avatar',
    },
  },
  emits: ['update:modelValue', 'updateThumbnail'],
  data() {
    return {
      avatar: this.initialAvatar as Media|null,
      collection: this.mediaCollection,
    }
  },
  methods: {
    onChange(event: HTMLInputEvent) {
      if (! event.target.files?.length || ! event.target.files[0].type.includes('image')) {
        return;
      }
      
      const file = event.target.files![0];
      const thumbnail = URL.createObjectURL(file);
      
      this.avatar = {
        id: 0,
        thumbnail,
        fullscreen: '',
        caption: '',
      };

      this.$emit('updateThumbnail', thumbnail);
      
      this.handleFile(file);
    },

    setMedia(media: Media) {
      this.$emit('update:modelValue', media.id);
    },

    removeImage() {
      this.avatar = null;
      this.$emit('update:modelValue', null);
      this.$emit('updateThumbnail', null);
    },
  }
})
</script>
