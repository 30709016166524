<template>
  <div
    v-if="isOpen" 
    v-click-outside="close"
    class="absolute left-0 top-0 z-100 mt-14 w-full border-gray-400 bg-white shadow-lg sm:left-auto sm:right-0 sm:mr-4 sm:w-full sm:max-w-md sm:border sm:border-t-0 lg:mr-0"
    :class="[transparent ? 'sm:rounded-lg' : 'sm:rounded-b-lg']"
  >
    <div class="flex">
      <input
        ref="input"
        v-model="form.data.s"
        type="text"
        class="form-element form-element-lg border-none focus:ring-0"
        placeholder="Vad letar du efter?"
        autocomplete="off"
        @input="onInput"
        @keyup.esc="close"
      >
    </div>
    
    <div class="full-minheight-with-submenu overflow-y-scroll border-t border-gray-200 sm:max-h-96 sm:min-h-10">
      <template v-if="categories.length > 0">
        <template v-for="(category, n) in categories" :key="n">
          <a
            v-for="result in category.results"
            :key="result.id"
            :href="resultUrl(category, result)"
            class="group flex items-center px-3 py-2 hover:bg-gray-100"
          >
            <div class="mr-3 size-12 shrink-0">
              <img
                v-if="result.fullscreen"
                class="size-full rounded-full object-cover"
                :src="result.fullscreen"
              >
              <div
                v-else 
                class="flex size-full items-center justify-center rounded-full bg-gray-200"
              >
                <fa-icon icon="search" />
              </div>
            </div>
          
            <div class="flex-1">
              <div class="font-medium text-black group-hover:underline">
                {{ result.title ?? result.name }}
              </div>
              <div class="text-sm text-gray-700">
                {{ category.name }}
              </div>
            </div>
          </a>
        </template>
      </template>
      
      <div
        v-else-if="! form.isProcessing && form.data.s.length > 0 && emptyResults"
        class="p-6 text-center text-gray-700"
      >
        Vi kunde inte hitta några resultat!
      </div>
      
      <div
        v-else-if="! form.isProcessing && ! emptyResults"
        class="p-6 text-center text-gray-700"
      >
        Sök efter artiklar, raser, ämnen och kennlar. 
        Hundannonser hittar du <a :href="window.route('ads.index')" class="underline">här.</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { debounce } from "lodash-es";
import Form from "@/library/form";
import { Article, Breed, Kennel, Tag } from "@/@types";
import FaIcon from "@/components/FaIcon.vue";

type SearchCategoryKeys = 'articles' | 'breeds' | 'kennels' | 'tags'

type SearchResult = Article|Breed|Kennel|Tag|any;

type SearchCategory = {
  name: string;
  results: SearchResult[];
};

type SearchCategories = {
  [category in SearchCategoryKeys]: SearchCategory;
};

export default defineComponent({
  components: { FaIcon },
  props: {
    transparent: {
      type: Boolean,
      required: true,
    },
  }, 
  emits: ['open', 'close'],
  data() {
    return {
      isOpen: false,
      form: new Form({
        s: '',
      }),
      categories: [] as SearchCategory[],
      emptyResults: false,
    }
  },
  methods: {
    open() {
      this.isOpen = true;
      this.$emit('open');
      this.$nextTick(() => this.$refs.input.focus());
    },
    
    close() {
      this.isOpen = false;
      this.$emit('close');
    },
    
    onInput() {
      this.emptyResults = false;
      this.search();
    },
    
    search: debounce(function(this: any) {
      this.searchDebounced();
    }, 250),

    searchDebounced() {
      if (this.form.data.s.length < 1) {
        this.categories = [];
      } else {
        this.form.get<{ categories: SearchCategories }>(window.route('globalSearch'))
            .then(({ categories }) => {
              this.filterCategories(categories);
              this.emptyResults = this.categories.length === 0;
            });
      }
    },
    
    filterCategories(categories: SearchCategories) {
      this.categories = Object.values(categories)
          .filter((category) => category.results.length);
    },

    resultUrl(category: SearchCategory, result: SearchResult): string {
      switch (category.name) {
        case 'Artiklar':
          return window.route('articles.show', { article: result.slug });
        case 'Vanliga frågor':
          return window.route('help', { s: result.title });
        case 'Raser':
          return window.route('breeds.show', { slug: result.slug });
        case 'Ämnen':
          return window.route('tags.show', { tag: result.slug });
        case 'Kennlar':
          return window.route('kennels.show', { kennel: result.slug });
        default:
          return '#';
      }
    },
  },
})
</script>
