import { defineComponent } from "vue";
import { Media } from "@/@types";
import Compressor from "compressorjs";
import toastStore from "@/store/toast-store";
import Form from "@/library/form";

export default defineComponent({
  props: {
    order: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mediaForm: new Form(null, false, true),
      collection: 'temporary',
    }
  },
  methods: {
    async handleFile(file: File) {
      if (file.type.includes('video')) {
        return this.compressVideo(file);
      } else {
        return this.compressImage(file, 1200, this.uploadFile);
      }
    },

    async compressImage(file: File, size: number, callback: (file: File|Blob) => void) {
      return new Promise<void>((resolve, reject) => {
        new Compressor(file, {
          maxWidth: size,
          maxHeight: size,
          success: (file) => {
            callback(file);
            resolve();
          },
          error: (err) => {
            console.log(err.message);
            toastStore.error('Kunde inte hantera en bild.');
            reject(err);
          },
        });
      })
    },

    async compressVideo(file: File) {
      return new Promise<void>((resolve, reject) => {
        if (file.size > 50 * 1000000) {
          toastStore.error('En video du har valt är för stor. Max 50MB.');
          this.onError(file);
          reject();
        } else {
          this.uploadFile(file);
          resolve();
        }
      });
    },

    uploadFile(file: File|Blob) {
      this.mediaForm
        .withSubmitData({ file, order: this.order, collection_name: this.collection })
        .post<{ media: Media }>(window.route('media.store'))
        .then(({ media }) => {
          this.setMedia(media, file);
        })
    },

    onError(file: File|Blob) {},
    
    setMedia(media: Media, file: File|Blob) {},
  },
})
