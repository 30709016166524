<template>
  <modal
    ref="modal"
    title="Inställningar"
    :subtitle="subtitle"
    :type="ModalType.Success"
    :callback="submit"
    :is-processing="form.isProcessing || (!form.data.email && !form.data.phone_number)"
    :record-errors="false"
    :initial-open="initialOpen"
  >
    <form @submit.prevent="submit">
      <fieldset>
        <div class="mt-4 grid grid-cols-6 gap-6">
          <div class="col-span-6">
            <label for="image" class="form-label">Profilbild</label>
            <media-input
              ref="mediaInput"
              v-model="form.data.image"
              :errors="form.errors"
              :initial-avatar="user!.avatar"
            />
          </div>
          
          <div class="col-span-6 sm:col-span-3">
            <label for="email" class="form-label">E-post</label>
            <email-input v-model="form.data.email" input-class="mt-1 form-element" />
            <form-error :errors="form.errors" field="email" />
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="phone_number" class="form-label">Mobilnummer</label>
            <input
              id="phone_number"
              v-model="form.data.phone_number"
              type="text"
              name="phone_number"
              autocomplete="tel"
              class="form-element mt-1"
            >
            <form-error :errors="form.errors" field="phone_number" />
          </div>

          <div class="col-span-6">
            <label for="share_data" class="form-label">Dela data</label>
            
            <div class="flex items-start">
              <div class="flex h-5 items-center">
                <input
                  id="share_data"
                  v-model="form.data.share_data"
                  type="checkbox"
                  class="form-checkbox border-2 border-gray-500"
                >
              </div>
              <div class="ml-1.5 text-sm">
                <label for="share_data" class="text-gray-700">
                  Genom att kryssa i rutan accepterar du ta emot information och marknadsföring från våra samarbetspartners. Läs mer under våra <a :href="window.route('terms')" target="_blank" class="underline">Användarvillkor</a>.
                </label>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
    
    <template v-if="$slots.default" #trigger>
      <slot />
    </template>
  </modal>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { ModalType } from "@/@types";
  import Form from "@/library/form";
  import FormError from "@/components/FormError.vue";
  import toastStore from "@/store/toast-store";
  import Modal from "@/components/Modal.vue";
  import userStore from "@/store/user-store";
  import EmailInput from "@/components/EmailInput.vue";
  import MediaInput from "@/components/MediaInput.vue";

  export default defineComponent({
    components: { MediaInput, EmailInput, Modal, FormError },
    props: {
      callback: {
        type: Function as PropType<() => void>,
        default: null,
      },
      initialOpen: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        form: new Form({
          image: userStore.getState().user!.avatar?.id ?? null,
          email: userStore.getState().user!.email,
          phone_number: userStore.getState().user!.phone_number,
          contact_state_inbox: true,
          contact_state_email: userStore.getState().user!.contact_state_email,
          contact_state_phone: userStore.getState().user!.contact_state_phone,
          send_email_notifications: userStore.getState().user!.send_email_notifications,
          send_email_news: userStore.getState().user!.send_email_news,
          send_email_offers: userStore.getState().user!.send_email_offers,
          send_sms_notifications: userStore.getState().user!.send_sms_notifications,
          send_sms_offers: userStore.getState().user!.send_sms_offers,
          share_data: userStore.getState().user!.share_data === null 
            ? 0 
            : userStore.getState().user!.share_data,
        }),
        ModalType,
      }
    },
    computed: {
      subtitle(): string {
        return 'Du måste ange både e-post och mobilnummer för att kunna publicera annonser och kontakta säljare.';
      },
    },
    methods: {
      submit(): Promise<void> {
        if (this.$refs.mediaInput.mediaForm.isProcessing) {
          toastStore.error('Du måste vänta tills alla filer har laddats upp.')
          return new Promise((_, reject) => reject());
        }
        
        return this.form.patch(window.route('users.update'))
          .then(() => {
            userStore.setAvatar(this.$refs.mediaInput.avatar);
            toastStore.success();
            
            if (this.form.data.email?.length && this.form.data.phone_number?.length) {
              this.$refs.modal.close();
              this.callback && this.callback();
            }
          })
      },
    },
  });
</script>
