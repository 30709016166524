import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faChevronRight,
  faSearch,
  faPlusCircle,
  faCommentLines,
  faHeart,
  faEllipsisH,
  faEllipsisV,
  faSignIn,
  faDog,
  faDogLeashed,
  faBone,
  faMedkit,
  faUserCircle,
  faPaw,
  faBan,
  faExclamationTriangle,
  faExpand,
  faTimes,
  faMapMarkerAlt,
  faExternalLink,
  faCrosshairs,
  faUser,
  faVenus,
  faMars,
  faVenusMars,
  faBirthdayCake,
  faChevronLeft,
  faArrowsAltV,
  faBars,
  faBolt,
  faStar,
  faBadgeCheck,
  faPencil,
  faEnvelope,
  faPlus,
  faHomeHeart,
  faCalendarDay,
  faUserSecret,
  faLifeRing,
  faChevronDown,
  faChevronUp,
  faTelescope,
  faBook,
  faWeightHanging,
  faShieldCheck,
  faMale,
  faCheck,
  faAt,
  faPhone,
  faSpinnerThird,
  faPaperPlane,
  faCommentSlash,
  faUndo,
  faTrashAlt,
  faQuestionCircle,
  faEye,
  faShoppingCart,
  faFileSignature,
  faCopy,
  faCamera,
  faFilm,
  faAlignLeft,
  faList,
  faObjectUngroup,
  faSitemap,
  faChartNetwork,
  faPhotoVideo,
  faMobile,
  faShare,
  faImage,
  faFileAlt,
  faFillDrip,
  faUsers,
  faPoll,
  faCog,
  faGlobeEurope,
  faArrowRight,
  faArrowLeft,
  faCreditCard,
  faLink,
  faMobileAlt,
  faDesktopAlt,
  faGripLines,
  faBold,
  faItalic,
  faStrikethrough,
  faListUl,
  faListOl,
  faIndent,
  faOutdent,
  faUnderline,
  faStore,
  faEnvelopeOpenDollar,
  faMoneyCheckEditAlt,
  faThumbsUp,
  faHandshake,
  faShoppingBag,
  faBell,
  faHistory,
  faSlidersH,
  faFilePdf,
  faVideo,
  faUserHeadset,
  faHouseDay,
  faTag,
  faCoins,
  faClipboardListCheck,
  faCrown,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faHeart as fasHeart,
  faStar as fasStar,
  faStarHalf as fasStarHalf,
  faQuoteRight as fasQuoteRight,
  faPlay,
  faPause,
  faCaretUp,
  faCaretDown,
  faMinus,
  faBolt as fasBolt,
  faShield,
  faShieldCheck as fasShieldCheck,
  faBadgeCheck as fasBadgeCheck,
  faFingerprint,
  faSearch as fasSearch,
  faBadgePercent,
  faTag as fasTag,
  faUser as fasUser,
  faCrown as fasCrown,
  faPhotoVideo as fasPhotoVideo,
  faCertificate,
  faPaw as fasPaw,
  faImage as fasImage,
  faRotate as fasRotate,
  faFileSignature as fasFileSignature,
  faUserHeadset as fasUserHeadset,
  faQuestionCircle as fasQuestionCircle,
  faChartSimple,
  faCheck as fasCheck,
  faTimes as fasTimes,
  faTriangle,
  faTrophy,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faPlus as falPlus,
  faExternalLink as falExternalLink,
  faFillDrip as falFillDrip,
  faFileAlt as falFileAlt,
  faCog as falCog,
} from '@fortawesome/pro-light-svg-icons'

import {
  faFacebook,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

library.add(
// @ts-ignore
  faFacebook,
  faInstagram,
  faTwitter,
  faChevronRight,
  faSearch,
  faPlusCircle,
  faCommentLines,
  faHeart,
  faEllipsisH,
  faEllipsisV,
  faSignIn,
  faDog,
  faDogLeashed,
  faBone,
  faMedkit,
  faUserCircle,
  faPaw,
  faBan,
  faExclamationTriangle,
  faExpand,
  faTimes,
  faMapMarkerAlt,
  faExternalLink,
  faCrosshairs,
  faUser,
  faVenus,
  faMars,
  faVenusMars,
  faBirthdayCake,
  faChevronLeft,
  faArrowsAltV,
  faBars,
  faBolt,
  faStar,
  faBadgeCheck,
  faPencil,
  faEnvelope,
  faPlus,
  faHomeHeart,
  faCalendarDay,
  faUserSecret,
  faLifeRing,
  faCaretUp,
  faCaretDown,
  faChevronDown,
  faChevronUp,
  faTelescope,
  faBook,
  faWeightHanging,
  faShieldCheck,
  faMale,
  faCheck,
  faAt,
  faPhone,
  faSpinnerThird,
  faPaperPlane,
  faCommentSlash,
  faUndo,
  faTrashAlt,
  faQuestionCircle,
  fasQuestionCircle,
  faEye,
  fasHeart,
  fasStar,
  fasStarHalf,
  faShoppingCart,
  faFileSignature,
  faCopy,
  faCamera,
  faFilm,
  faAlignLeft,
  faList,
  faObjectUngroup,
  faSitemap,
  faChartNetwork,
  faChartSimple,
  faPhotoVideo,
  fasQuoteRight,
  faMobile,
  faShare,
  faImage,
  faPlay,
  faPause,
  faFileAlt,
  faFillDrip,
  faUsers,
  faPoll,
  faCog,
  faGlobeEurope,
  faArrowRight,
  faArrowLeft,
  faCreditCard,
  faLink,
  faMobileAlt,
  faDesktopAlt,
  faGripLines,
  faMinus,
  falPlus,
  falExternalLink,
  falFillDrip,
  falFileAlt,
  falCog,
  faBold,
  faItalic,
  faStrikethrough,
  faListUl,
  faListOl,
  faIndent,
  faOutdent,
  faUnderline,
  faShield,
  faCrown,
  fasBolt,
  faStore,
  fasBadgeCheck,
  faFingerprint,
  faEnvelopeOpenDollar,
  faMoneyCheckEditAlt,
  faThumbsUp,
  faHandshake,
  faShoppingBag,
  fasSearch,
  faBell,
  faBadgePercent,
  faTag,
  faSlidersH,
  faHistory,
  faFilePdf,
  faVideo,
  faUserHeadset,
  faHouseDay,
  fasTag,
  fasUser,
  faCoins,
  faClipboardListCheck,
  faCrown,
  fasCrown,
  fasPhotoVideo,
  faCertificate,
  fasPaw,
  fasImage,
  fasRotate,
  fasFileSignature,
  fasUserHeadset,
  fasShieldCheck,
  fasCheck,
  fasTimes,
  faTriangle,
  faTrophy,
);
