<template>
  <div
    class="fixed right-0 top-14 z-100 flex flex-col items-center p-2 sm:items-end"
    :class="{ 'pointer-events-none': state.toasts.length === 0 }"
  >
    <transition-group name="fade">
      <button
        v-for="toast in state.toasts"
        :key="toast.id"
        type="button"
        class="mb-2 flex max-w-xs items-center justify-between rounded border p-2 text-left text-sm font-medium shadow-sm sm:p-3 sm:text-base"
        :class="{
          'border-gray-300 bg-white text-gray-700': toast.type === ModalType.None,
          'border-green-600 bg-green-500 text-white': toast.type === ModalType.Success,
          'border-red-600 bg-red-500 text-white': toast.type === ModalType.Danger,
        }"
        @click="remove(toast.id)"
      >
        <span class="mr-2 sm:mr-3">{{ toast.text }}</span>
        <fa-icon icon="times" class="opacity-50" />
      </button>
    </transition-group>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import toastStore from "@/store/toast-store";
import { ModalType } from '@/@types';

export default defineComponent({
  data() {
    return {
      state: toastStore.getState(),
      ModalType,
    }
  },
  methods: {
    remove(id: number): void {
      toastStore.removeToast(id);
    },
  },
});
</script>
