<template>
  <div id="modals" class="absolute z-100" />
  
  <toasts />
  <live-chat-facade />
  <furry-frame-box v-if="false" />
  <ask-for-user-information />
  <premium-info-modal />

  <layout-menu />

  <div class="bg-gray-200">
    <slot />
  </div>

  <layout-footer />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import LayoutMenu from "./LayoutMenu.vue";
import LayoutFooter from "./LayoutFooter.vue";
import Toasts from "@/layout/Toasts.vue";
import LiveChatFacade from "@/components/LiveChatFacade.vue";
import FurryFrameBox from "@/components/FurryFrameBox.vue";
import userStore from "@/store/user-store";
import { AuthedUser, Kennel } from "@/@types";
import AskForUserInformation from "@/components/AskForUserInformation.vue";
import PremiumInfoModal from "@/components/PremiumInfoModal.vue";

export default defineComponent({
  components: { PremiumInfoModal, AskForUserInformation, LiveChatFacade, Toasts, LayoutFooter, LayoutMenu, FurryFrameBox },
  props: {
    sharedUser: {
      type: Object as PropType<AuthedUser>,
      required: false,
    },
    sharedKennel: {
      type: Object as PropType<Kennel>,
      required: false,
    },
    sharedHasProfile: {
      type: Boolean,
      required: true,
    },
    sharedHasPremium: {
      type: Boolean,
      required: true,
    },
    sharedUnseenMatches: {
      type: Number,
      required: true,
    },
  },
  created() {
    userStore.setup(this.sharedUser ?? null, this.sharedKennel ?? null, this.sharedHasProfile, this.sharedHasPremium, this.sharedUnseenMatches);

    const appHeight = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
    window.addEventListener('resize', appHeight)
    appHeight();
  },
  mounted() {
    this.$nextTick(this.onLoad);
  },
  methods: {
    onLoad() {
      if (! window.performance && window.performance.getEntriesByType) return;

      const entries = window.performance.getEntriesByType("navigation");

      if (entries.length === 0) return;

      const navTiming = entries[0];
      const pageLoadTimeMillisec = navTiming.loadEventEnd - navTiming.startTime;
      const pageLoadTimeSec = this.milliSecondsToSeconds(pageLoadTimeMillisec);
      const renderTimeMillisec = Math.round(window.performance.now() - navTiming.loadEventEnd);
      
      if (pageLoadTimeSec > 0 && pageLoadTimeSec < 30) {
        window.gtag('event', 'page_load_time', {
          page_load_count: 1,
          page_load_time_sec: pageLoadTimeSec,
          render_time_millisec: renderTimeMillisec,
        });
      }
    },
    
    milliSecondsToSeconds(delta: number) {
      return Math.round(((delta / 1000) + Number.EPSILON) * 100) / 100;
    },
  }
});
</script>
