<template>
  <div id="modals" class="absolute z-100" />
  <toasts />
  
  <shop-layout-menu />

  <div class="bg-gray-100">
    <slot />
  </div>

  <shop-layout-footer />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AuthedUser, ShopCategory, ShopManufacturer, ShopProduct } from "@/@types";
import ShopLayoutMenu from "@/layout/ShopLayoutMenu.vue";
import shopStore from "@/store/shop-store";
import userStore from "@/store/user-store";
import layoutStore from "@/store/layout-store";
import ShopLayoutFooter from "@/layout/ShopLayoutFooter.vue";
import Toasts from "@/layout/Toasts.vue";

export default defineComponent({
  components: { ShopLayoutFooter, ShopLayoutMenu, Toasts },
  props: {
    sharedUser: {
      type: Object as PropType<AuthedUser>,
      required: false,
    },
    sharedCategories: {
      type: Array as PropType<ShopCategory[]>,
      required: true,
    },
    sharedManufacturers: {
      type: Array as PropType<ShopManufacturer[]>,
      required: true,
    },
    sharedPopularProducts: {
      type: Array as PropType<ShopProduct[]>,
      required: true,
    },
  },
  created() {
    userStore.setup(this.sharedUser ?? null, null);
    shopStore.setup(this.sharedCategories, this.sharedManufacturers, this.sharedPopularProducts);
    layoutStore.setup();
  },
});
</script>

<style lang="scss">
body, pre {
  background-color: theme('colors.gray.100');
}
</style>
